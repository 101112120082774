
import { Component, Vue } from 'vue-property-decorator';
import ResearchPageHeader from '@/components/ResearchPageHeader.vue';
import ResearchsSearchForm, { SearchParamsObject } from '@/components/ResearchsSearchForm.vue';
import AudienceComparisonGrid from '@/components/AudienceComparisonGrid.vue';
import { DailyGridAverageDTO, DailyGridDTO, DailyGridExportParamsDTO, DailyGridSearchParamsDTO } from '@/data/dto/daily-grid.dto';
import ExportButton from '@/components/ExportToExcelButton.vue';
import { TargetBaseType, getTargetBaseTypeByTargetName } from '@/utils/viewers-projection.util';

@Component({
  components: {
    ResearchPageHeader,
    ResearchsSearchForm,
    AudienceComparisonGrid,
    ExportButton,
  }
})
export default class DailyGrid extends Vue {
  selectedTargetBaseType: TargetBaseType = 'residences';
  selectedConnectedTvSumTypeLabel = 'Total';
  defaultDailyGrid: DailyGridDTO = {
    mainTvNetwork: {
      name: '',
      color: '',
      programs: [],
    },
    secondaryTvNetworks: [],
  };
  dailyGrid: DailyGridDTO = { ...this.defaultDailyGrid };

  decimalPlaces = 0;

  average: DailyGridAverageDTO | null = null;

  currentSearchParams: {
    valueOnly: DailyGridSearchParamsDTO,
    completeData: Record<string, any>
  } | null = null;

  get exportToExcelSearchParams(): DailyGridExportParamsDTO | null {
    if(!this.currentSearchParams) return null;

    const params = this.currentSearchParams;

    const headerFields: string[] = [
      params.completeData.market.label,
      params.completeData.startDate,
      params.completeData.connectedTvsSumType.label,
      params.completeData.mainTvNetwork.label,
      params.completeData.startTime,
      params.completeData.endTime,
    ];
    const header = headerFields.join(' - ');
    return {
      dailyGrid: {
        params: params.valueOnly,
        header,
      }
    };
  }

  resetContent(): void {
    this.dailyGrid = {...this.defaultDailyGrid};
    this.average = null;
    this.currentSearchParams = null;
  }

  onDefaultFiltersValuesLoaded(params: SearchParamsObject): void {
    this.decimalPlaces = params.valueOnly.decimalPlaces || 0;
  }

  async getDailyGrid(params: SearchParamsObject): Promise<void> {
    this.resetContent();

    const searchParams = params.valueOnly as DailyGridSearchParamsDTO;

    if (params.completeData.target) {
      this.selectedTargetBaseType = getTargetBaseTypeByTargetName(params.completeData.target.label);
    }

    if (params.completeData.connectedTvsSumType) {
      this.selectedConnectedTvSumTypeLabel = params.completeData.connectedTvsSumType.label || 'Total';
    }

    // grid
    try {
      const grid = await this.$store.dispatch('getDailyGrid', searchParams);

      if (!grid) {
        this.$store.commit('showAlert', {
          message: 'Não há dados disponíveis para a consulta realizada.',
          type: 'warning'
        });
        this.resetContent();
        return;
      }

      this.dailyGrid = grid;
      this.currentSearchParams = {
        valueOnly: searchParams,
        completeData: params.completeData,
      };

      // average
      try {
        const average = await this.$store.dispatch('getDailyGridAverage', searchParams);
        if (!average) {
          this.$store.commit('showAlert', {
            message: 'Não há dados de minuto disponíveis para calcular a média de audiência da grade.',
            type: 'warning'
          });
          this.average = null;
          return;
        }
        this.average = average;
      } catch (e) {
        this.average = null;
      }
    } catch (e) {
      this.resetContent();
    }
  }
}
